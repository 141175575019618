import React from "react";
import * as styled from "./Services.styles";
import {
  ArchitectureService,
  DebuggingService,
  GameplayService,
  LeadershipService,
  MultiplayerService,
  OptimizationService,
  ToolsService,
  UnrealEngineService,
} from "../../images";
import { Service } from "./Service";

export const Services = () => {
  return (
    <>
      <styled.SectionTitle id={"services"}>OUR SERVICES</styled.SectionTitle>

      <styled.ServicesContainer>
        <Service
          image={GameplayService}
          title={"Gameplay"}
          subtitle={
            "Simplicity and maintainability is our goal when it comes to gameplay systems. Let us help you build:\n"
          }
          mainText={
            `
3rd person stylish action combat.
Hardcore FPS gun mechanics.
Heavily data driven RPG and progression systems.
Novel locomotion mechanics.
Conversation and cinematic systems to delivery great story.
Complex UIs like inventories, achievements, progression.
`
          }
        />
        <Service
          image={UnrealEngineService}
          title={"Unreal Engine 5"}
          subtitle={"Let us help you get the most out of this engine. We can:"}
          mainText={`
Review your code for common Unreal "gotchas" causing mysterious problems.
Improve startup times for the editor and game with async loading.
Optimize light build times.
Train your team to use advanced tools and features of the engine.
Implement powerful and slick editors for your game data in Slate.
Implement packaging and optimize disk footprint and patch size.
`}
        />
        <Service
          image={MultiplayerService}
          title={"Multiplayer"}
          subtitle={
            "Implementing server/client multiplayer systems correctly can be very tricky. We can:"
          }
          mainText={`
Apply proven techniques to make your game feel responsive under latency.
Audit your server to make sure it is secure from cheaters.
Optimize your systems to reduce server CPU and bandwidth usage, reducing your run costs.
Build debugging tools for other engineers and designers to hunt down and resolve difficult network race conditions and mis-predictions.
`}
        />
        <Service
          image={OptimizationService}
          title={"Optimization"}
          subtitle={
            "Building a game is hard. Building one that runs smoothly and is cost effective is much harder!"
          }
          mainText={`
There are many things that need optimizing, we do it all: CPU, GPU, hitches, load times, memory, bandwidth, disk, patch size.
We handle whatever platform you've got, Windows clients, Linux servers, consoles, etc.
Optimizing a game is a team effort. We can train engineers, artists, and designers to build code and content that will perform.
Analytics and tools for diagnosing performance issues is critical for live games. Let us design and implement those systems for you.
`}
        />

        <Service
          image={DebuggingService}
          title={"DEBUGGING"}
          subtitle={"Let us ruthlessly crush your bug backlog."}
          mainText={`
Fixing bugs is great, preventing them is even better. We can analyze your systems and suggest refactors to reduce your long-term costs.
You know that weird race condition bug that only reproes in shipping builds once every 10 hours? We can fix those.
Sometimes the best way to fix bugs is to build a tool to find and diagnose them. This is a technique we've applied to many other projects.`}
        />
        <Service
          image={ToolsService}
          title={"TOOLS"}
          subtitle={
            "Improve your content development efficiency. Better tools make better games."
          }
          mainText={`
Intuitive and efficient editor UIs built collaboratively with the developers that will actually use the tool.
Automated pipelines and workflows to help get content into the game quicker and with fewer errors.
Continuous Integration and build delivery tools to get the latest build into the hands of everyone with minimal fuss.`}
        />

        <Service
          image={ArchitectureService}
          title={"ARCHITECTURE"}
          subtitle={
            "In a well architected game, new features are easy to add, while new bugs are more difficult to introduce."
          }
          mainText={`
The early stages of a project can be critical for making good architectural decisions. Let us apply our extensive experience in the game industry to help you get off on the right foot.
If you feel like you're drowning in tech debt, we can resolve the core issues plaguing your game or development processes.
Live service games in particular have special considerations. You need systems that can deliver new content to your players frequently.`}
        />

        <Service
          image={LeadershipService}
          title={"LEADERSHIP"}
          subtitle={
            "It can take time to find an experienced engineering lead, we can plug that gap for new teams and studios."
          }
          mainText={`
Setup efficient processes, coding standards, and build systems.
Mentor and train engineers, leveling up your team.
Estimate project costs.
Implement a proven technical recruitment pipeline to build your own stellar engineering team.
`}
        />
      </styled.ServicesContainer>
    </>
  );
};
