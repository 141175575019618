import React from "react";
import * as styled from "./HeroImage.styles";
import { HomeBackground } from "../../images";
import { CTAButton } from "../button";
import { uiStore } from "../../stores";

export const HeroImage = () => {
  return (
    <styled.HeroImageContainer
      style={{ backgroundImage: `url(${HomeBackground})` }}
    >
      <styled.TextContainer>
        <styled.Title>QUALITY GAME ENGINEERING</styled.Title>
        <styled.Subtitle>
          Building games is hard, and the right expertise can make a world of
          difference
        </styled.Subtitle>
        <CTAButton to={"#"} onClick={() => uiStore.setDisplayContactForm(true)}>
          GET IN TOUCH
        </CTAButton>
      </styled.TextContainer>
    </styled.HeroImageContainer>
  );
};
