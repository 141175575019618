import styled from "styled-components";

export const HeroImageContainer = styled.div`
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background: yellow;
  width: 38%;
  margin-top: 38vh;
  height: 30%;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 20px;
    word-break: keep-all;
  }
`;

export const Title = styled.h2`
  font-size: 80px;
  font-family: "Poppins";
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.124;
  text-align: center;
  margin: 0;

  @media screen and (max-width: 1600px) {
    font-size: 50px;
  }

  @media (max-width: 1000px) {
    font-size: 30px;
    word-break: keep-all;
  }
`;

export const Subtitle = styled.p`
  font-size: 30px;
  font-family: "Poppins";
  color: rgb(255, 255, 255);
  text-align: center;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;
