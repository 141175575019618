import styled from "styled-components";

export const ServicesContainer = styled.div`
  width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 0;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 60px;
  font-family: "Poppins";
  color: rgb(38, 146, 255);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.667;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 40px;
  }
`;

export const ServiceBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  height: auto;
  margin: 20px 0px;
  border-radius: 35px 35px 0 0;

  background-color: rgb(255, 255, 255);
  filter: drop-shadow(0px 13px 23.68px rgba(0, 0, 0, 0.15));

  @media (max-width: 1000px) {
    width: 92%;
    margin: auto;
    text-align: center;
    height: unset;
    border-radius: 53px 53px 0 0;

  }
`;

export const BoxImageContainer = styled.div`
  //width: 100%;
  //height: 40%;
`;

export const BoxImage = styled.img`
  width: 100%;
  //height: 100%;
  object-fit: contain;
  
`;

export const BoxDescription = styled.div`
  height: 60%;
  width: 100%;
  padding: 5%;


  @media (max-width: 1000px) {
    width: 100%;
    padding: 20px;
  }
`;

export const BoxDescriptionTitle = styled.h4`
  font-size: 25px;
  font-family: "Poppins";
  color: rgb(47, 69, 92);
  line-height: 1.276;
  height: 20px;
  text-transform: uppercase;
  font-weight: normal;

`;

export const BoxDescriptionSubtitle = styled.span`
  display: block;
  font-size: 16px;
  font-family: "Poppins";
  color: rgb(47, 69, 92);
  line-height: 1.276;
  height: 50px;
  margin-bottom: 10px;
  font-weight: normal;


  @media (max-width: 1000px) {
    font-size: 18px;
  }
`;

export const BoxDescriptionText = styled.p`
  font-size: 14px;
  font-family: "Poppins";
  color: rgb(47, 69, 92);
  display: block;
  margin: 10px 0;
  white-space: pre-wrap;
  line-height: 1.8;
  font-weight: normal;


  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;
