import React from "react";
import * as styled from "./Services.styles";

type ServiceProps = {
  image: string;
  title: string;
  subtitle: string;
  mainText: string;
};
export const Service = ({ image, mainText, subtitle, title }: ServiceProps) => {
  return (
    <styled.ServiceBox>
      <styled.BoxImageContainer>
        <styled.BoxImage src={image} />
      </styled.BoxImageContainer>
      <styled.BoxDescription>
        <styled.BoxDescriptionTitle>{title}</styled.BoxDescriptionTitle>
        <styled.BoxDescriptionSubtitle>
          {subtitle}
        </styled.BoxDescriptionSubtitle>

       
          <styled.BoxDescriptionText>{mainText}</styled.BoxDescriptionText>
      
      </styled.BoxDescription>
    </styled.ServiceBox>
  );
};
