import styled from "styled-components";

export const GameEngineeringContainer = styled.div`
  height: 600px;
  margin-top: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-left: 10%;
  gap:50px;
  

  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
    margin-left: 0;
    padding: 20px;
    height: unset;

    a {
      margin: auto;
    }
  }
`;

export const Column = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  white-space: break-spaces;
  position: relative;
  height: 100%;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
`;

export const Title = styled.div`
  font-size: 25px;
  font-family: "Poppins";
  color: rgb(47, 69, 92);
  line-height: 1.667;

  @media (max-width: 1000px) {
    text-align: center;
  }
`;

export const List = styled.div`
  font-size: 18px;
  margin-top: 20px;
  font-family: "Poppins";
  color: rgb(47, 69, 92);
  line-height: 1.667;
  margin-bottom: 40px;

  text-decoration: none;
  list-style: none;
`;

export const ListItem = styled.span`
  display: block;
  margin-top: 10px;
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  top: -5%;
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: 1000px) {
    display: none;
  }
`;
