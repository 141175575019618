import styled from "styled-components";

export const LogoGrid = styled.img`
  width: 65%;
  margin-left: 15%;
  margin-top: 10%;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 50px;
    margin: 0;
  }
`;
