import React from "react";
import { CTAButton } from "../button";
import * as styled from "./GameEngineering.styles";
import { GameEngineering as Image } from "../../images";
import {UseGoToSection} from "../../hooks";

export const GameEngineering = () => {
  const {goTo} = UseGoToSection()
  return (
    <styled.GameEngineeringContainer id={"game_engineering"}>
      <styled.Column>
        <styled.Title>
          Let us apply our extensive experience from AAA development to your
          problems:
        </styled.Title>
        <styled.List>
          <styled.ListItem>
            Implementing tricky gameplay features.
          </styled.ListItem>
          <styled.ListItem>Solving difficult bugs.</styled.ListItem>
          <styled.ListItem>
            Building latency tolerant, secure, multiplayer systems.{" "}
          </styled.ListItem>
          <styled.ListItem>
            Creating tools and pipelines to amplify productivity.{" "}
          </styled.ListItem>
          <styled.ListItem>
            Optimizing for CPU, GPU, memory, and bandwidth.{" "}
          </styled.ListItem>
          <styled.ListItem>
            Porting projects to leading and emerging platforms.{" "}
          </styled.ListItem>
          <styled.ListItem>
            Architecting powerful and resilient systems.{" "}
          </styled.ListItem>
          <styled.ListItem>
            Building a plugin to adapt your technology to Unreal.{" "}
          </styled.ListItem>
        </styled.List>
        <CTAButton to={"/"} state={{section:"services"}}>LEARN MORE</CTAButton>
      </styled.Column>

      <styled.Column>
        <styled.Image src={Image} />
      </styled.Column>
    </styled.GameEngineeringContainer>
  );
};
