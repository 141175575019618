import React from "react";
import { Separator } from "./Separator";
import { CTAButton } from "../button/Button";
import styled from "styled-components";

const Title = styled.h2`
  font-size: 60px;
  font-family: "Poppins";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.667;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 40px;
  }
`;

const Subtitle = styled.p`
  font-size: 30px;
  font-family: "Poppins";
  color: rgb(255, 255, 255);
  line-height: 1.667;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  padding: 0% 10%;

  @media (max-width: 1000px) {
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
`;

export const HiringBanner = () => {
  return (
    <Separator>
      <Container>
        <Title>WE ARE HIRING</Title>
        <Subtitle>Apply now and join our team</Subtitle>
        <CTAButton to={"/careers"}>LEARN MORE</CTAButton>
      </Container>
    </Separator>
  );
};
