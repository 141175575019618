import * as React from "react";
import "../index.css";
import { PageLayout } from "../components/layout/PageLayout";
import { HeroImage } from "../components/hero";
import { GameEngineering } from "../components/gameEngineering/GameEngineering";
import { LogoGrid } from "../components/LogoGrid/LogoGrid";
import { HiringBanner } from "../components/Separator/HiringBanner";
import { Services } from "../components/Services/Services";
import { GetInTouch } from "../components/Separator/GetInTouch";
import { useScrollToSection } from "../hooks";
import { RouteState } from "../types/RouteState";

const IndexPage = ({ location }: RouteState) => {
  useScrollToSection(location.state?.section);
  return (
    <PageLayout>
      <HeroImage />
      <GameEngineering />
      <LogoGrid />
      <HiringBanner />
      <Services />
      <GetInTouch margin={"0 0"} />
    </PageLayout>
  );
};

export default IndexPage;
